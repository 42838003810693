
import { companyLineOfBusiness } from "@/helpers/selectOptions";
import { authMapState } from "@/store/modules/auth";
import { ICompany } from "@/store/modules/companies/types";
import { quoteMapActions } from "@/store/modules/quote";
import { sortArrayOfObjects } from "@/helpers";
import { agencyMapActions, agencyMapGetters } from "@/store/modules/agency";
import Vue from "vue";
import { uniq } from "lodash";

interface ILineOfBusinessData {
  policyType: string;
  errorMessage: string;
  loadingText: string;
  agencyCodeOptions: string[];
  selectedAgencyCode: string;
}

export default Vue.extend({
  name: "company-line-of-business",
  components: {
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue"),
    EzlynxSelectAgencyCode: () =>
      import("./Components/EzlynxSelectAgencyCode.vue")
  },
  data(): ILineOfBusinessData {
    return {
      policyType: "",
      errorMessage: "",
      loadingText: "",
      agencyCodeOptions: [],
      selectedAgencyCode: ""
    };
  },
  mounted() {
    if (this.$isCurrentUserEzlynx) {
      this.fetchEzlynxAgencyCodes().then(() => {
        this.$modal.show("selectAgencyCodeModal");
      });
      return;
    }
  },
  computed: {
    ...authMapState(["initData"]),
    ...agencyMapGetters(["getAgencyByAgencyCode"]),
    companiesData(): ICompany[] {
      if (
        this.$getCurrentUser &&
        (this.$getCurrentUser.role === "admin" ||
          this.$getCurrentUser.role === "underwriting" ||
          this.$getCurrentUser.role === "claims" ||
          this.$getCurrentUser.role === "billing" ||
          this.$getCurrentUser.role === "dataEntry")
      ) {
        return this.initData?.companies || [];
      } else if (this.$isCurrentUserEzlynx) {
        return this.selectedAgency.agencyAdminData.companiesData;
      } else {
        return this.$getCurrentUser.companiesData;
      }
    },
    userHasValidAgency(): boolean {
      if (
        this.$getCurrentUser &&
        this.$getCurrentUser.role &&
        (this.$getCurrentUser.role === "admin" ||
          this.$getCurrentUser.role === "underwriting" ||
          this.$getCurrentUser.role === "claims" ||
          this.$getCurrentUser.role === "billing" ||
          this.$getCurrentUser.role === "dataEntry")
      )
        return true;
      if (this.$isCurrentUserEzlynx && this.selectedAgencyCode) {
        return this.verifyAgencyData(this.selectedAgency);
      }
      if (this.$getCurrentUser && this.$getCurrentUser.agencyData) {
        return this.verifyAgencyData(this.$getCurrentUser.agencyData);
      }
      return false;
    },
    selectedAgency(): any {
      return this.getAgencyByAgencyCode(this.selectedAgencyCode);
    }
  },
  methods: {
    ...quoteMapActions(["createQuote"]),
    ...agencyMapActions(["getAgencies"]),
    lineOfBusinessOptions(): any[] {
      //show only those that agent can quote in
      let processedLineOfBusinessOptions;
      if (this.$isCurrentUserAdmin) {
        processedLineOfBusinessOptions = [...companyLineOfBusiness];
      } else {
        let allowedLinesOfBusiness: string[] = [];
        allowedLinesOfBusiness.push("PL");
        if (this.companiesData.length > 0) {
          for (const allowedCompany of this.companiesData) {
            for (const lineOfBusiness of allowedCompany.companyLineOfBusiness) {
              allowedLinesOfBusiness.push(lineOfBusiness);
            }
          }
        } else {
          this.errorMessage =
            "User can't rate because there are no quoting companies assigned. Please Contact administrator";
        }
        allowedLinesOfBusiness = uniq(allowedLinesOfBusiness);
        processedLineOfBusinessOptions = companyLineOfBusiness.filter(
          (option: any) => allowedLinesOfBusiness.includes(option.value)
        );
      }
      if (!this.$isCurrentUserEzlynx) {
        processedLineOfBusinessOptions.push({
          value: "PL",
          label: "PERSONAL LIABILITY APPLICATION"
        });
      }
      return processedLineOfBusinessOptions;
    },
    async toolbarSelectItem(event: string): Promise<void> {
      try {
        switch (event) {
          case "next": {
            const { policyType } = this;
            let url = "";
            if (policyType === "PL") {
              let base_PL_URL = "/personal-liability-policies";
              const role = this.$getCurrentUser.role;
              if (role === "admin" || role === "underwriting") {
                url = `${base_PL_URL}`;
              } else {
                url = `/new${base_PL_URL}`;
              }
            } else {
              const payload = {
                policyType,
                ...(this.$isCurrentUserEzlynx && {
                  onBehalfOfEmail: this.selectedAgency.agencyAdminData.email,
                  onBehalfOfAgentCode: this.selectedAgencyCode
                })
              };
              const response = await this.createQuote(payload);
              url = `/quotes/${response._id}`;

              this.$router.push(url);
              break;
            }
            this.$router.push(url);
            break;
          }
          case "cancel":
            this.$router.go(-1);
            break;
        }
      } catch (error) {
        this.$appNotifyError(error.message);
      }
    },
    verifyAgencyData(agencyData: any): boolean {
      const { physicalAddress = {}, status } = agencyData;
      const { city, zipCode, state, streetName } = physicalAddress;
      return [
        !!city,
        !!zipCode,
        !!state,
        !!streetName,
        status === "approved"
      ].every((criterion: any) => !!criterion);
    },
    async fetchEzlynxAgencyCodes(): Promise<void> {
      try {
        this.loadingText = "Fetching agency codes. Please await...";
        const agencies = await this.getAgencies({
          query: {
            allowEzlynxRating: true,
            _id__in: this.$getCurrentUser.ezlynxAgencies
          }
        });
        this.agencyCodeOptions = agencies.map((agency: any) => {
          return { label: agency.agencyCode, value: agency.agencyCode };
        });
        this.agencyCodeOptions = sortArrayOfObjects(
          this.agencyCodeOptions,
          "label"
        );
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.errorMessage = "Error fetching agencies. Try again later.";
      } finally {
        this.loadingText = "";
      }
    },
    async setEzlynxAgencyCode($event: string): Promise<void> {
      this.selectedAgencyCode = $event;
      this.$modal.hide("selectAgencyCodeModal");
    }
  }
});
