var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"topActionButtonPrimary":{
    text: 'Next',
    key: 'next',
    disabled: !_vm.policyType
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancel'
  },"pageTitle":"Line of Business Selection","showTopActionButtonPrimaryAtBottom":true},on:{"toolbarSelectItem":_vm.toolbarSelectItem}},[_c('custom-alert',{attrs:{"title":"Invalid User Agency Details","show":!!_vm.errorMessage,"dismissible":false,"type":"warning"}},[_c('p',[_vm._v(_vm._s(_vm.errorMessage))])]),(_vm.userHasValidAgency)?_c('div',{staticClass:"ml-4"},[_c('h1',{staticClass:"text-lg mb-4 font-medium"},[_vm._v("Select Line of Business")]),_c('div',{staticClass:"flex flex-col sm:flex-row mb-12 w-full"},_vm._l((_vm.lineOfBusinessOptions()),function(option,index){return _c('RadioBlockField',{key:`radio-line-of-business-${index}`,staticClass:"mb-4 sm:mb-0 w-full md:w-1/4 md:h-48",class:{
          'mr-0 sm:mr-4': index !== _vm.lineOfBusinessOptions().length - 1
        },attrs:{"active":option.value === _vm.policyType,"value":option.value,"label":option.label},on:{"input":function($event){_vm.policyType = $event}}})}),1)]):_c('div',[_c('custom-alert',{attrs:{"title":"Invalid User Agency Details","show":true,"dismissible":false,"type":"info"}},[_c('p',[_vm._v("User does not have an approved agency assigned to it")]),_c('p',[_vm._v("Or the user's agency may not have a valid physical address")]),_c('p',[_vm._v("Contact administrators to rectify the situation")]),_c('p',[_vm._v("Try again when all those issues have been resolved")])])],1),_c('ModalBase',{attrs:{"name":"selectAgencyCodeModal","size":"medium","title":"Please select an agency code","clickToClose":false,"showClose":true}},[_c('EzlynxSelectAgencyCode',{attrs:{"agencyCodeOptions":_vm.agencyCodeOptions},on:{"close":function($event){return _vm.$modal.hide('selectAgencyCodeModal')},"agencyCodeSelected":_vm.setEzlynxAgencyCode}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }